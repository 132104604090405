import { useMemo } from "react";

import { AdminApi } from "@/apis/connect/admin-api";
import { AuthApi } from "@/apis/connect/auth-api";
import { BusinessCategoryApi } from "@/apis/connect/business-category-api";
import { CompanyApi } from "@/apis/connect/company-api";
import { GroupApi } from "@/apis/connect/group-api";
import { ImageApi } from "@/apis/connect/image-api";
import { MeetingApi } from "@/apis/connect/meeting-api";
import { PrimaryItemApi } from "@/apis/connect/primary-item-api";
import { ProjectApi } from "@/apis/connect/project-api";
import { SecondaryItemApi } from "@/apis/connect/secondary-item-api";
import { TaskApi } from "@/apis/connect/task-api";
import { UserApi } from "@/apis/connect/user-api";
import { WorkspaceApi } from "@/apis/connect/workspace-api";
import { WorkspaceUserApi } from "@/apis/connect/workspace-user-api";
import { authTokenInfoAtom } from "@/global-states/common-atoms";

import { useAtom } from "jotai";
import { PrimaryItemTplApi } from "@/apis/connect/primary-item-tpl-api";
import { SecondaryItemTplApi } from "@/apis/connect/secondary-item-tpl-api";
import { TaskTplApi } from "@/apis/connect/task-tpl-api";

export const useApiConnector = () => {
  const [authTokenInfo, _] = useAtom(authTokenInfoAtom);

  const accessToken = authTokenInfo?.access_token;

  const adminApi = useMemo(() => new AdminApi(accessToken), [accessToken]);
  const authApi = useMemo(() => new AuthApi(accessToken), [accessToken]);
  const businessTypeApi = useMemo(() => new BusinessCategoryApi(accessToken), [accessToken]);
  const companyApi = useMemo(() => new CompanyApi(accessToken), [accessToken]);
  const groupApi = useMemo(() => new GroupApi(accessToken), [accessToken]);
  const imageApi = useMemo(() => new ImageApi(accessToken), [accessToken]);
  const meetingApi = useMemo(() => new MeetingApi(accessToken), [accessToken]);
  const primaryItemApi = useMemo(() => new PrimaryItemApi(accessToken), [accessToken]);
  const primaryItemTplApi = useMemo(() => new PrimaryItemTplApi(accessToken), [accessToken]);
  const projectApi = useMemo(() => new ProjectApi(accessToken), [accessToken]);
  const secondaryItemApi = useMemo(() => new SecondaryItemApi(accessToken), [accessToken]);
  const secondaryItemTplApi = useMemo(() => new SecondaryItemTplApi(accessToken), [accessToken]);
  const taskApi = useMemo(() => new TaskApi(accessToken), [accessToken]);
  const taskTplApi = useMemo(() => new TaskTplApi(accessToken), [accessToken]);
  const userApi = useMemo(() => new UserApi(accessToken), [accessToken]);
  const workspaceApi = useMemo(() => new WorkspaceApi(accessToken), [accessToken]);
  const workspaceUserApi = useMemo(() => new WorkspaceUserApi(accessToken), [accessToken]);

  return {
    adminApi,
    authApi,
    businessTypeApi,
    companyApi,
    groupApi,
    imageApi,
    meetingApi,
    primaryItemApi,
    primaryItemTplApi,
    projectApi,
    secondaryItemApi,
    secondaryItemTplApi,
    taskApi,
    taskTplApi,
    userApi,
    workspaceApi,
    workspaceUserApi,
  };
};
